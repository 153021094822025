<template>
    <Layout class="home">
        <template #banner>
            <template v-if="banners.length > 0">
                <Swiper :data="banners">
                    <template slot-scope="{ slide }">
                        <img
                            :src="$static(slide)"
                            style="width: 100%"
                        >
                    </template>
                </Swiper>
            </template>
            <template v-else>
                <v-skeleton-loader type="image" />
            </template>
        </template>
        <h1 class="text-center">
            NEST考試時程表
        </h1>
        <br>
        <br>
        <v-expansion-panels>
            <v-expansion-panel
                v-for="(item, i) in list"
                :key="i"
            >
                <v-expansion-panel-header>
                    <b>{{ item.date | formatDate }}｜{{ item.title }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--color-primary-light)">
                    <pre style="color: var(--color-primary); padding-top: 1em">
                        {{ item.content }}
                    </pre>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <div class="d-flex justify-center">
            <Pagination
                :total="total"
                :page-size="paginationOptions.itemsPerPage"
                :current-page="paginationOptions.page"
                background
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </Layout>
</template>

<script>
import { mapActions } from 'vuex';
import Swiper from '@/components/Swiper.vue';
import { Pagination } from 'element-ui';

export default {
    components: {
        Swiper,
        Pagination,
    },
    data() {
        return {
            banners: [],
            expanded: [],
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            list: [],
        };
    },
    created() {
        this.BannerList().then(({ data }) => {
            this.banners = data.raw?.map(({ file }) => file);
        });
        this.getScheduleList();
    },
    methods: {
        ...mapActions('mHome', ['BannerList', 'ScheduleList']),
        getScheduleList() {
            const { page, itemsPerPage } = this.paginationOptions;
            this.ScheduleList({
                offset: (page - 1) * itemsPerPage,
                limit: itemsPerPage,
            }).then((res) => {
                this.list = res.data.raw;
                this.total = res.data.total;
            });
        },
        handleSizeChange(size) {
            this.paginationOptions.itemsPerPage = size;
            this.getScheduleList();
        },
        handleCurrentChange(page) {
            this.paginationOptions.page = page;
            this.getScheduleList();
        },
    },
};
</script>

<style lang="scss" scoped></style>
