<template>
    <!-- <section
        class="chart"
        style="
            overflow: auto;
            width: 100%;
            padding: 30px;
            border-radius: 20px;
            background: var(--var-background-darkblue-rgb);
        "
    >
        <div style="min-width: 700px">
        </div>
    </section> -->
    <div>
        <canvas ref="myChart" />
        <img
            ref="ChartImg"
            style="display: none;"
        >
    </div>
</template>

<script lang="ts">
import Chartjs from 'chart.js/auto';
import Vue from 'vue';

export default Vue.extend({
    props: {
        chartData: {
            type: Object,
            default: () => ({}),
        },
        chartName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            desktopMode: false,
        };
    },
    mounted() {
        this.init(this.$refs.myChart);
    },
    methods: {
        init(el) {
            if (el) {
                // eslint-disable-next-line no-new
                new Chartjs(el, {
                    type: 'radar',
                    options: {
                        responsive: true,
                        scales: {
                            r: {
                                pointLabels: {
                                    font: {
                                        size: 14,
                                    },
                                },
                                suggestedMin: 0,
                                suggestedMax: 8,
                            },
                        },
                        layout: {
                            autoPadding: true,
                        },
                        interaction: {
                            mode: 'index',
                            intersect: false,
                            axis: 'x',
                        },
                        animation: {
                            onComplete: () => {
                                const image = el.toDataURL('image/png');
                                this.$refs.ChartImg.src = image;
                                this.$emit('getChartImg', { name: this.chartName, img: image });
                            },
                        },
                    },
                    data: this.chartData,
                });
            }
        },
    },
});
</script>
