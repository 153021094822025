<template>
    <v-card>
        <!-- <textarea
            :value="JSON.stringify(groupData, null, 4)"
            readonly
            style="height: 500px; width: 100%"
        /> -->
        <div style="background: var(--color-secondary); color: #fff">
            <v-card-title class="text-h5 text-center justify-center font-weight-bold">
                {{ $route.meta.subtitle }}
            </v-card-title>
        </div>
        <div class="pa-5 pa-sm-10">
            <div class="d-flex justify-end">
                <v-btn
                    outlined
                    color="var(--color-primary)"
                    :disabled="list.length===0?true:false"
                    @click="downloadCardList()"
                >
                    匯出所有資料
                    <span class="material-icons-outlined md-18">file_download</span>
                </v-btn>
            </div>
            <v-data-table
                :items="list"
                :headers="[
                    { text: '姓名', value: 'name' },
                    { text: '報考項目', value: 'competition.name' },
                    { text: '准考證號碼', value: 'admitCardId' },
                    { text: '考場', value: 'competition.place' },
                    { text: '', value: 'actions', sortable: false },
                ]"
                :items-per-page="10"
            >
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        small
                        outlined
                        color="var(--color-primary)"
                        class="px-5"
                        @click="
                            assignPersonalData(item);
                            $router.push(`/group/admit-card/${searchID}/${item.hash || 'error'}`);
                        "
                    >
                        檢視
                    </v-btn>
                </template>
            </v-data-table>
            <br>
            <v-btn
                dark
                rounded
                class="d-block ma-auto px-10"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="$router.go(-1)"
            >
                回上頁
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { downloadFile } from '@/utils/methods';
import jwt from 'jsonwebtoken';

export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['searchID'],
    data() {
        return {
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
        };
    },
    computed: {
        ...mapState('mApply', ['groupData', 'personalData']),
        total() {
            return this.groupData.total || 0;
        },
        list() {
            return this.groupData.raw || [];
        },
    },
    methods: {
        ...mapActions([
            'mApply/groupDownload',
            'mApply/groupQuery',
        ]),
        ...mapMutations([
            'mApply/assignPersonalData',
        ]),
        assignPersonalData(data) {
            this['mApply/assignPersonalData'](data);
        },
        downloadCardList() {
            const decodeData = jwt.decode(localStorage.getItem(process.env.VUE_APP_TOKEN_KEY));
            downloadFile('apply.xlsx', () => this['mApply/groupDownload'](decodeData));
            this['mApply/groupQuery'](decodeData);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/mixins/dashed-border.scss";
.text-overflow-ellipsis {
    @include text-overflow-ellipsis(1);
}
</style>
