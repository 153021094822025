import API from '@/api';
import * as utils from '../utils';

export default {
    namespaced: true,
    state: {
        activityData: {
            id: '',
            name: '',
            title: '',
            applyStart: '',
            applyEnd: '',
            payDeadline: '',
            publicResultTime: '',
            qualification: '',
            fee: '',
            giveaway: {
                thumb: '',
                title: '',
                description: '',
            },
            prize: {
                thumb: '',
                title: '',
                description: '',
            },
            examTimetables: [],
        },
    },
    actions: {
        Fetch({ state }, id) {
            return API.Activity.PublicFetchResource(id)
                .then((res) => {
                    state.activityData = res.data;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
    },
};
