<!-- eslint-disable max-len -->
<template>
    <v-card>
        <div style="background: var(--color-secondary); color: #fff">
            <v-card-title class="text-h5 text-center justify-center font-weight-bold">
                {{ $route.meta.subtitle }}
            </v-card-title>
        </div>
        <div class="pa-5 pa-sm-10">
            <v-data-table
                :items="list"
                :headers="[
                    { text: '姓名', value: 'name' },
                    { text: '報考項目', value: 'competition.name' },
                    { text: '准考證號碼', value: 'admitCardId' },
                    { text: '', value: 'actions', sortable: false },
                ]"
                :items-per-page="10"
            >
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        small
                        outlined
                        color="var(--color-primary)"
                        class="px-5"
                        :to="`/${nextRoute}/grades-inquiries/${searchID}/${item.hash || 'error'}/${item.activity}`"
                    >
                        查看成績
                    </v-btn>
                </template>
            </v-data-table>
            <br>
            <v-btn
                dark
                rounded
                class="d-block ma-auto px-10"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="$router.go(-1);"
            >
                回上頁
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['searchID'],
    data() {
        return {
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
        };
    },
    computed: {
        ...mapState('mApply', [
            'groupData',
            'historyData',
        ]),
        total() {
            if (this.$route.path.includes('group')) return this.groupData.total;
            if (this.$route.path.includes('history')) return this.historyData.total;
            return 0;
        },
        list() {
            if (this.$route.path.includes('group')) return this.groupData.raw;
            if (this.$route.path.includes('history')) return this.historyData.raw;
            return [];
        },
        nextRoute() {
            if (this.$route.path.includes('group')) return 'group';
            if (this.$route.path.includes('history')) return 'history';
            return '';
        },
    },
    methods: {
        // handleClear() {
        //     localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
        //     this.$router.go(-1);
        // },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/mixins/dashed-border.scss";
.text-overflow-ellipsis {
    @include text-overflow-ellipsis(1);
}
</style>
