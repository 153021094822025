<template>
    <Layout class="about">
        <template #banner>
            <Banner
                title="NEST 起源"
                :src-desktop="require('@/assets/about/banner.png')"
                :src-mobile="require('@/assets/about/banner-mobile.png')"
            />
        </template>
        <template v-for="(item, i) in list">
            <div
                :key="i"
                class="
                    card
                    d-flex
                    flex-column flex-sm-row
                    align-center
                    py-sm-10
                    px-sm-5
                    py-5
                    px-3
                    mb-5
                "
            >
                <h2 class="title flex-shrink-0 mr-sm-5 mb-5 mb-sm-0">
                    {{ item.title }}
                </h2>
                <div>
                    {{ item.content }}
                    <ol>
                        <li
                            v-for="(subitem, j) in item.sublist"
                            :key="j"
                        >
                            {{ subitem }}
                        </li>
                    </ol>
                </div>
            </div>
        </template>
        <h3
            class="text-center mt-sm-10"
            style="letter-spacing: 2px; color: var(--color-primary)"
        >
            NEST全國小學學科能力競賽，期待您一起參與！
        </h3>
    </Layout>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            list: [
                {
                    title: '成立宗旨',
                    content: '全國多元優質教育發展協會自2023年起開辦全國小學學科能力競賽（National Elementary Subjects Test），本測驗是為全國小學生舉辦的公開大型學科檢定，經由科學化的分析，提供國小生各科目的詳細學習指標，為全國首創國小生的全科能力競賽！',
                },
                {
                    title: '競賽目的',
                    content: 'NEST全國小學學科能力競賽希望達成以下目的：',
                    sublist: [
                        '增加學生學習動機，產生成就感，進而累積學科能力。',
                        '透過準備測驗的過程，激勵自己學習成長，提高主動學習力。',
                        '讓國小學生進一步交流學習狀況，互相切磋琢磨，提升競爭力。',
                        '多樣化的評量方式及賽後成績分析，針對學習癥結改善，提供學習建議，以達更好的學習效果。',
                    ],
                },
                {
                    title: '考題規劃',
                    content: 'NEST全國小學學科能力競賽追求卓越的考題品質以及公正性，特別規劃如下：',
                    sublist: [
                        '各科由多位資深教師擔任命題教師群，考題多元有鑑別度。',
                        '考前透過預試分析考題鑑別度，以提升測驗分數的信度與效度。',
                    ],
                },
            ],
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-width: 2px 0;
    border-style: solid;
    border-color: #e2e2e2;

    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::after {
            content: "";
            display: block;
            height: 5px;
            width: 100%;
            border-radius: 100px;
            background: var(--color-highlight);
        }
    }
}
</style>
