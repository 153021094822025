var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('div',{staticStyle:{"background":"var(--color-secondary)","color":"#fff"}},[_c('v-card-title',{staticClass:"text-h5 text-center justify-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$route.meta.subtitle)+" ")])],1),_c('div',{staticClass:"pa-5 pa-sm-10"},[_c('v-data-table',{attrs:{"items":_vm.list,"headers":[
                { text: '姓名', value: 'name' },
                { text: '報考項目', value: 'competition.name' },
                { text: '准考證號碼', value: 'admitCardId' },
                { text: '', value: 'actions', sortable: false },
            ],"items-per-page":10},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-btn',{staticClass:"px-5",attrs:{"small":"","outlined":"","color":"var(--color-primary)","to":`/${_vm.nextRoute}/grades-inquiries/${_vm.searchID}/${item.hash || 'error'}/${item.activity}`}},[_vm._v(" 查看成績 ")])]}}])}),_c('br'),_c('v-btn',{staticClass:"d-block ma-auto px-10",style:({
                background: 'var(--color-primary-gradient)',
            }),attrs:{"dark":"","rounded":""},on:{"click":function($event){return _vm.$router.go(-1);}}},[_vm._v(" 回上頁 ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }