import moment from 'moment';
import FileSaver from 'file-saver';
import * as utils from '@/store/utils';

export function generateNumberArray(start = 0, end = 0) {
    const arr = [];
    for (let i = start; i <= end; i += 1) {
        arr.push(i);
    }
    return arr;
}

export function formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
}

export function getNowChineseYear() {
    const year = moment().format('YYYY');
    // eslint-disable-next-line radix
    return parseInt(year) - 1911;
}

export function downloadFile(filename, callback) {
    return callback()
        .then((res) => {
            FileSaver.saveAs(res.data, filename);
            return Promise.resolve(res);
        })
        .catch(utils.handleAPIError);
}
