<template>
    <v-app style="background: #f9f9f9">
        <Header />
        <v-main>
            <router-view />
        </v-main>
        <v-footer
            dark
            class="justify-center py-10 px-3"
        >
            <div
                class="d-flex flex-wrap align-center"
                style="max-width: 885px"
            >
                <img
                    src="@/assets/footer.png"
                    alt="Logo"
                    width="100"
                    class="mr-5"
                >
                <div class="d-flex flex-wrap flex-grow-1">
                    <div class="d-flex flex-column pa-5">
                        <p class="mb-3">
                            <b>NEST客服專線</b>
                        </p>
                        <p class="ma-0">
                            04-2452-8152
                        </p>
                    </div>
                    <v-divider
                        vertical
                        class="d-none d-sm-flex"
                    />
                    <div class="d-flex flex-column pa-5">
                        <p class="mb-3">
                            <b>NEST辦公室服務時間</b>
                        </p>
                        <p class="ma-0 d-flex flex-wrap">
                            <span>週二～週五 下午2:00～晚上9:00&emsp;</span><span>週六 上午10:00～下午5:00</span>
                        </p>
                    </div>
                </div>
            </div>
        </v-footer>
        <v-footer padless>
            <v-col
                class="text-center"
                cols="12"
            >
                全國多元優質教育發展協會版權所有©
            </v-col>
        </v-footer>
        <v-fab-transition>
            <v-btn
                v-show="backTopVisible"
                color="var(--color-secondary)"
                dark
                fab
                fixed
                bottom
                right
                small
                @click="handleBackTop()"
            >
                <v-icon size="2em">
                    mdi-chevron-up
                </v-icon>
            </v-btn>
        </v-fab-transition>
    </v-app>
</template>

<script>
import Header from '@/components/Header.vue';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'App',
    components: {
        Header,
    },
    data: () => ({
        backTopVisible: false,
    }),
    computed: {
        ...mapState('mSetting', ['settings']),
    },
    async created() {
        await this['mSetting/PublicInfo']();
        this['mActivity/Fetch'](this.settings['當前活動']);
        this['mCompetition/List']({
            filter: `activity:${this.settings['當前活動']}`,
        });
    },
    mounted() {
        window.addEventListener('scroll', () => {
            if (window.scrollY >= 10 && !this.backTopVisible) {
                this.backTopVisible = true;
            } else if (window.scrollY < 10 && this.backTopVisible) {
                this.backTopVisible = false;
            }
        });
    },
    methods: {
        ...mapActions([
            'mSetting/PublicInfo',
            'mActivity/Fetch',
            'mCompetition/List',
        ]),
        handleBackTop() {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        },
    },
};
</script>
