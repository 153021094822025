import API from '@/api';
import * as utils from '../utils';

export default {
    namespaced: true,
    state: {
        personalData: {},
        groupData: {},
        historyData: {},
        newName: null,
        scoreData: {
        },
    },
    mutations: {
        assignPersonalData(state, payload) {
            state.personalData = payload;
        },
    },
    actions: {
        getNewName({ state }, value) {
            state.newName = value;
        },
        personalApply(__, { id, data }) {
            return API.ApplyUtil.Create(id, data)
                .then((res) => res)
                .catch(utils.handleAPIError);
        },
        personalQuery({ state }, {
            activity,
            identity,
            month,
            day,
        }) {
            return API.ApplyUtil.PersonalQuery(activity, {
                identity,
                month,
                day,
            })
                .then((res) => {
                    state.personalData = res.data;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        groupQuery({ state }, {
            activity,
            cellphone,
            password,
        }) {
            return API.ApplyUtil.GroupQuery(activity, {
                cellphone,
                password,
            })
                .then((res) => {
                    if (res.data.raw.length > 0) {
                        state.groupData = res.data;
                        return res;
                    }
                    return 'error';
                })
                .catch(utils.handleAPIError);
        },
        groupDownload({ state }, {
            activity,
            cellphone,
            password,
        }) {
            return API.ApplyUtil.GroupDownload(activity, {
                cellphone,
                password,
            })
                .then((res) => {
                    state.groupData = res.data;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        historyQuery({ state }, {
            identity,
            month,
            day,
        }) {
            return API.ApplyUtil.HistoryQuery({
                identity,
                month,
                day,
            })
                .then((res) => {
                    state.historyData = res.data;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        scoreQuery({ state }, { activity, hash }) {
            return API.ApplyUtil.ScoreQuery(activity, {
                hash,
            })
                .then((res) => {
                    state.scoreData = res.data;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
        certificationQuery({ state }, { activity, hash, pdf }) {
            return API.ApplyUtil.CertificationQuery(activity, {
                hash,
                pdf,
            })
                .then((res) => {
                    if (!pdf) {
                        const fr = new FileReader();
                        // eslint-disable-next-line func-names
                        fr.onload = function (e) {
                            state.scoreData = {
                                ...state.scoreData,
                                certificationTemplate: e.target.result,
                            };
                        };
                        fr.readAsDataURL(res.data);
                    }
                    return res;
                });
        },
        GenerateMpg(__, serial) {
            return API.ApplyUtil.GenerateMpg(serial, {
                ReturnURL: `${process.env.VUE_APP_BASE_URL}/registration/personal/pay-complete`,
                ClientBackURL: `${process.env.VUE_APP_BASE_URL}/registration/personal/pay-complete`,
            }).then((res) => Promise.resolve(res));
        },
    },
};
