<template>
    <v-card class="admin-card-search">
        <!-- <textarea
            :value="JSON.stringify(data, null, 4)"
            readonly
            style="width: 100%; height: 500px; overflow: auto"
        /> -->
        <div style="background: var(--color-secondary); color: #fff">
            <v-card-title class="text-h5 text-center justify-center font-weight-bold">
                {{ $route.meta.subtitle }}
            </v-card-title>
        </div>
        <div class="pa-5 pa-sm-10">
            <v-simple-table>
                <tbody>
                    <tr>
                        <th>姓名</th>
                        <td>{{ data.name }}</td>
                    </tr>
                    <tr>
                        <th>准考證號碼</th>
                        <td>{{ data.admitCardId }}</td>
                    </tr>
                    <tr>
                        <th>報考項目</th>
                        <td>
                            <v-select
                                v-if="isEdit"
                                v-model="competitionId"
                                :items="competitionList"
                                outlined
                                class="select"
                                @change="changeId()"
                            />
                            <div
                                v-else
                                class="name"
                            >
                                <template v-if="data.competition">
                                    <div>{{ data.competition.name }}</div>
                                </template>
                                <v-btn
                                    v-if="!data.paid"
                                    style="background: var(--color-secondary); color: #fff"
                                    class="ml-2 sm-button"
                                    @click="editCompetitionName()"
                                >
                                    修改
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>考場地點</th>
                        <td>
                            <v-select
                                v-if="isEdit"
                                v-model="place"
                                :items="placeList"
                                outlined
                                class="select"
                            />
                            <div
                                v-else
                                class="d-flex flex-wrap align-center"
                            >
                                <span class="mr-1">
                                    <template v-if="data.competition">
                                        {{ data.competition.place }}
                                    </template>
                                </span>
                                <template v-if="data.competition && data.competition.location">
                                    <div class="d-flex align-center">
                                        <v-btn
                                            :href="
                                                $static(data.competition.location.planimetricMap)
                                            "
                                            target="_blank"
                                            x-small
                                            outlined
                                            color="var(--color-primary)"
                                        >
                                            試場平面圖
                                        </v-btn>
                                        <v-icon>mdi-map-marker</v-icon>
                                    </div>
                                </template>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>考試日期</th>
                        <td>
                            <div v-if="isEdit">
                                {{ date }}
                            </div>
                            <template v-if="data.competition && !isEdit">
                                {{ data.competition.date | formatDate }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <th>考試時間</th>
                        <td>
                            <div v-if="isEdit">
                                {{ time }}
                            </div>
                            <template v-if="data.competition && !isEdit">
                                {{ data.competition.time }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <th>競賽須知</th>
                        <td>
                            <v-btn
                                small
                                outlined
                                color="var(--color-primary)"
                                to="/competition-notice"
                            >
                                點我查看
                            </v-btn>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="2">
                            <template v-if="data.competition">
                                {{ data.competition.publicResultTime | formatDate }}
                            </template>
                            開放上網查詢成績
                        </th>
                    </tr>
                </tbody>
            </v-simple-table>
            <br>
            <div
                v-if="isEdit"
                style="display: flex;justify-content: center;"
            >
                <v-btn
                    dark
                    rounded
                    class="btn d-block px-10 mx-2"
                    :style="{
                        background: 'var(--color-primary-gradient)',
                    }"
                    @click="sureCompetitionName()"
                >
                    確定修改
                </v-btn>
                <v-btn
                    dark
                    rounded
                    class="btn d-block px-10 mx-2"
                    :style="{
                        background: '#B3B3B3',
                    }"
                    @click="isEdit = false"
                >
                    取消
                </v-btn>
            </div>
            <v-btn
                v-else
                dark
                rounded
                class="d-block ma-auto px-10"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="$router.go(-1)"
            >
                回上頁
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import API from '@/api';
import { mapActions, mapState } from 'vuex';
import jwt from 'jsonwebtoken';
import * as utils from '../store/utils';

export default {
    data() {
        return {
            isEdit: false,
            competitionId: '',
            place: '',
            date: '',
            time: '',
            newId: '',
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        ...mapState('mApply', ['personalData', 'newName']),
        ...mapState({
            competitionList: (state) => state.mCompetition.competitionList?.map((item) => ({
                ...item,
                text: item.name,
                value: item.id,
            })) || [],
        }),
        ...mapState('mSetting', ['settings']),
        data() {
            return this.personalData;
        },
        placeList() {
            return (
                this.competitionList
                    .find((item) => this.competitionId === item.id)
                    ?.locations?.map((item) => ({
                        ...item,
                        // text: `${item.name}（${item.address}`,
                        // eslint-disable-next-line prefer-template
                        text: item.name + '（' + item.address + '）',
                        value: item.name,
                    })) || []
            );
        },
    },
    // watch: {
    //     competitionList: {
    //         handler(newList) {
    //             this.nameOption = newList.map((item) => item.name);
    //             this.placeOption = newList.map((item) => item.place);
    //         },
    //         deep: true,
    //     },
    // },
    mounted() {

    },
    methods: {
        ...mapActions([
            'mApply/personalQuery',
        ]),
        changeId() {
            this.date = this.competitionList
                .find((item) => this.competitionId === item.id)?.date.split('T')[0];
            this.time = this.competitionList
                .find((item) => this.competitionId === item.id)?.time;
            if (this.competitionId !== this.newId) {
                this.place = this.placeList[0].value;
                this.newId = this.competitionId;
            }
        },
        editCompetitionName() {
            this.competitionId = this.competitionList
                .find((item) => this.data.competition.name === item.name)?.id;
            this.place = this.data.competition.place;
            this.date = this.data.competition.date;
            this.time = this.data.competition.time;
            this.newId = this.competitionId;
            this.isEdit = true;
        },
        async sureCompetitionName() {
            this.isEdit = false;
            try {
                const { id } = this.data;
                const competition = this.competitionId;
                const identity = this.$route.params.searchID;
                const { place } = this;
                const data = {
                    competition,
                    identity,
                    place,
                };
                await API.ApplyUtil.ChangeCompetition(id, data);
                const decodeData = jwt.decode(localStorage.getItem(process.env.VUE_APP_TOKEN_KEY));
                await this['mApply/personalQuery'](decodeData);
            } catch (e) {
                utils.handleAPIError(e);
            }
        },
    },
};
</script>

<style lang="scss">
.admin-card-search {
    th {
        white-space: nowrap;
        width: 155px;
    }
    .sm-button {
        padding: 4px 16px !important;
    }
    .v-btn__content {
        font-size: 12px !important;
    }
    .select{
        .v-text-field__details{
            display: none;
        }
        .v-input__slot{
            margin-bottom: 10px;
            margin-top: 10px;
            max-width: 500px;
        }
    }
    .btn{
        padding: 5px 50px !important;
    }
}
@media (min-width:750px){
    .admin-card-search {
        .btn{
            padding: 5px 60px !important;
        }
    }
}
</style>
