<template>
    <div class="pb-10 pb-sm-16">
        <h2 class="title d-flex align-center justify-center pb-sm-5 pb-3 pl-10">
            <span>考試範圍</span>
            &nbsp;
            <img
                src="../assets/V.png"
                width="50"
            >
        </h2>
        <div
            class="pa-5"
            style="max-width: 1000px; margin: auto"
        >
            <template v-for="(subjects, grade) in list">
                <div
                    :key="grade"
                    class="d-flex flex-column align-center"
                    style="position: relative"
                >
                    <h3 class="list-type-title mt-10">
                        {{ grade }}
                    </h3>
                    <div
                        v-for="(item, j) in subjects"
                        :key="j"
                        class="d-flex downloadScope"
                        style="width: 100%;"
                    >
                        <v-btn
                            v-if="item.subject ==='命題範圍'"
                            outlined
                            color="var(--color-primary)"
                            :disabled="list.length===0?true:false"
                            :href="$static(item.pdfLink)"
                            target="_blank"
                        >
                            下載詳細命題範圍及範例試題
                            <span class="material-icons-outlined md-18">file_download</span>
                        </v-btn>
                    </div>
                    <v-expansion-panels class="mt-2">
                        <v-expansion-panel
                            v-for="(item, j) in subjects"
                            :key="j"
                        >
                            <v-expansion-panel-header v-if="item.subject !=='命題範圍'">
                                <h3>{{ item.subject }}</h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <ol
                                    class="mb-3"
                                    style="font-weight: lighter"
                                >
                                    <li
                                        v-for="(scope, k) in item.examScopeList"
                                        :key="k"
                                    >
                                        {{ scope }}
                                    </li>
                                </ol>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            list: [],
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
    },
    watch: {
        // eslint-disable-next-line func-names
        'activityData.id': function () {
            this.handleList();
        },
    },
    created() {
        if (this.activityData.id) {
            this.handleList();
        }
    },
    methods: {
        ...mapActions('mScope', ['List']),
        handleList() {
            const params = {
                filter: `activity:${this.activityData.id}`,
            };
            this.List(params).then((res) => {
                this.list = res.data?.raw?.reduce((obj, item) => {
                    if (obj[item.grade]) obj[item.grade].push(item);
                    // eslint-disable-next-line no-param-reassign
                    else obj[item.grade] = [item];
                    return obj;
                }, {});
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    height: 100px;
    position: relative;
    color: #fff;
    background-image: url("../assets/title.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (min-width: 600px) {
        height: 200px;
    }
}

.list-type-title {
    display: list-item;
    list-style-type: disc;
    margin-bottom: 10px;
    &::marker {
        color: var(--color-primary);
    }
    @media screen and (min-width: 600px) {
        margin-bottom: 25px;
    }
}
.downloadScope{
    justify-content: center;
    position: relative;
    @media screen and (min-width: 600px) {
        position: absolute;
        top: 55px;
        justify-content: flex-end;
    }
}
</style>
