<template>
    <Layout class="about">
        <template #banner>
            <Banner
                title="常見問題"
                :src-desktop="require('@/assets/question/banner.png')"
                :src-mobile="require('@/assets/question/banner-mobile.png')"
            >
                <span style="color: #000">常見問題</span>
            </Banner>
        </template>
        <v-row class="my-0">
            <v-col
                sm="8"
                lg="6"
                class="mx-auto"
            >
                <v-btn-toggle
                    :value="type"
                    rounded
                    mandatory
                    dense
                    active-class="active-tab"
                    class="d-flex mb-sm-10"
                >
                    <template v-for="item in menu">
                        <v-btn
                            :key="item.text"
                            :value="item.value"
                            :to="`/question/${item.value}`"
                        >
                            {{ item.text }}
                        </v-btn>
                    </template>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <br>
        <v-expansion-panels>
            <v-expansion-panel
                v-for="(item, i) in list"
                :key="i"
            >
                <v-expansion-panel-header>
                    <b class="header">
                        <div
                            class="q-pretext"
                            style="
                                color: var(--color-primary);
                                font-size: 1.2em;
                            "
                        >Q：</div>
                        <div class="q-title">
                            {{ item.title }}
                        </div>

                    </b>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--color-primary-light)">
                    <pre
                        style="color: var(--color-primary); padding-top: 1em"
                    >{{ item.content }}</pre>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <div class="d-flex justify-center">
            <Pagination
                :total="total"
                :page-size="paginationOptions.itemsPerPage"
                :current-page="paginationOptions.page"
                background
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </Layout>
</template>

<script>
import { mapActions } from 'vuex';
import { Pagination } from 'element-ui';

export default {
    components: {
        Pagination,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            menu: [
                { text: '賽事資訊', value: '賽事資訊' },
                { text: '考試內容', value: '考試內容' },
                { text: '其他', value: '其他' },
            ],
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            list: [
                {
                    title: '你好你好你好你好你好你好你好你好你好?',
                    content: '你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好? 你好你好你好你好?你好你好你好你好你好你好你好你好你好? ',
                },
                {
                    title: '你好你好你好你好你好你好你好你好你好?',
                    content: '你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好? 你好你好你好你好?你好你好你好你好你好你好你好你好你好? ',
                },
                {
                    title: '你好你好你好你好你好你好你好你好你好?',
                    content: '你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好? 你好你好你好你好?你好你好你好你好你好你好你好你好你好? ',
                },
                {
                    title: '你好你好你好你好你好你好你好你好你好?',
                    content: '你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好?你好你好你好你好你好你好你好你好你好? 你好你好你好你好?你好你好你好你好你好你好你好你好你好? ',
                },
            ],
        };
    },
    created() {
        this.getList(this.type);
    },
    methods: {
        ...mapActions('mQuestions', ['QuestionList']),
        getList(type) {
            const { page, itemsPerPage } = this.paginationOptions;
            this.QuestionList({
                offset: (page - 1) * itemsPerPage,
                limit: itemsPerPage,
                filter: type ? `type:${type}` : '',
            }).then((res) => {
                this.list = res.data.raw;
                this.total = res.data.total;
            });
        },
        handleSizeChange(size) {
            this.paginationOptions.itemsPerPage = size;
            this.getScheduleList();
        },
        handleCurrentChange(page) {
            this.paginationOptions.page = page;
            this.getScheduleList();
        },
    },
    beforeRouteUpdate(to, from, next) {
        const { type } = to.params;
        this.getList(type);
        next();
    },
};
</script>

<style lang="scss" scoped>
.active-tab {
    color: #fff !important;
    background: var(--color-highlight) !important;
    border: none !important;
}

.header{
    display: flex;
    .q-pretext{
        display: flex;
        line-height: 1.5;
    }
    .q-title{
        font-size: 1.2em;
        line-height: 1.5;
    }
}

.v-btn-toggle {
    min-width: 80%;
    margin: auto;

    > * {
        flex: 1;
    }
}
</style>
